<template>
  <div >
    <md-notice-bar
        icon="volumn"
        multi-rows
    >
      {{welcomeMsg}}
    </md-notice-bar>
    <div class="info">
      <md-field >
        <md-cell-item title="选举签到" arrow @click="qiandao" :addon="qiandaoQingkuang"  />
        <md-cell-item title="选举理事单位" arrow @click="onClick(1)" />
        <md-cell-item title="选举常务理事单位" arrow @click="onClick(2)" />
        <md-cell-item title="选举领导班子成员单位" arrow @click="onClick(3)" />
      </md-field>
    </div>

  </div>
</template>

<script>
import {Field, CellItem, Dialog,NoticeBar, Icon, Toast} from 'mand-mobile'

export default {
  name: "ToupiaoRuKou",

  components: {
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
  },

  data() {
    return {
      open: false,
      qiandaoQingkuang:'',
      welcomeMsg: '',
    }
  },
  mounted() {
    document.title = '投票系统'
    this.checkQiandao()
  },
  methods: {
    checkQiandao(){
       this.$API.qiandao.checkQiandao().then(res=>{
         console.log(res)
         this.welcomeMsg  = res.data.wecolmemsg
         if (res.errno == 0){
            this.qiandaoQingkuang = '未签到'
         }else{
           this.qiandaoQingkuang = res.msg

         }
       })
    },
    // 签到
    qiandao(){
      this.$API.toupiao.qiandao().then(res=>{
        this.checkQiandao()
        if (res.errno == 0){
          Toast.succeed('签到成功')
        }else{
          Toast.failed(res.msg)
        }
      })
    },
    onClick(id) {
      this.$router.push('/toupiao/'+id)
    },
  },
}
</script>

<style lang="stylus" scoped>
.holder
  display block
  width 48px
  height 48px
  background-color #E6E6E6
/deep/ .md-field
  padding 0
  width 100%
/deep/ .md-cell-item-body
  padding  0
.info
  margin 30px
</style>
